/* You can add global styles to this file, and also use other style files */
@use 'reset';
@use 'primeng/primeng';
@use 'primeflex/primeflex';

@use 'sds/breakpoints' as *;
@use 'sds/elements' as *;
@use 'sds/fonts' as *;
@use 'sds/typography' as *;
@use 'synergy/colors' as colors;

@import './loading-animation';

html {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: $font-size-default;
  line-height: 22px;
  color: colors.$darkgray-1;
  height: 100%;
}

body {
  background-color: colors.$white;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: $font-size-default;
  color: colors.$darkgray-1;
  text-align: left;
  line-height: 22px;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

div {
  box-sizing: border-box;
}

.app-container {
  display: flex;
  flex-direction: column;
  position: relative;

  margin-left: auto;
  margin-right: auto;
  padding: 2em 5%;
}

h1 {
  margin-top: 24px;
  margin-bottom: 12px;
  color: colors.$text;
}

h2 {
  margin-top: 22px;
  margin-bottom: 11px;
  color: colors.$text;
}

h3 {
  margin-top: 16px;
  margin-bottom: 8px;
  color: colors.$text;
}

h4 {
  margin-top: 14px;
  margin-bottom: 7px;
  color: colors.$text;
}

h5 {
  margin-top: 12px;
  margin-bottom: 6px;
  color: colors.$text;
}

h6 {
  margin-top: 11px;
  margin-bottom: 6px;
  color: colors.$text;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
}

strong {
  font-weight: $font-bold;
}

a {
  color: colors.$primary;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  &:link {
    color: colors.$primary;
    text-decoration: none;
  }
  &:visited {
    color: colors.$primary;
    text-decoration: none;
  }
  &:focus {
    color: colors.$primary-light;
    text-decoration: none;
  }
  &:hover {
    color: colors.$primary-light;
    text-decoration: none;
  }
  &:active {
    color: colors.$primary;
    text-decoration: none;
  }
}

// class for assetname in toasts
.toast-asset-name {
  font-weight: $font-semibold;
}

.sick-toast {
  min-width: 35em;
}

.align-with-text-field {
  margin-top: 10px;
}

input {
  width: 100%;
  color: colors.$darkgray-1;
  min-width: 0px !important;
  border: solid colors.$darkgray-2;
  border-width: 1px;
  height: 35px;
  text-indent: 15px;
  background-color: colors.$white;
  font-size: 14px;
}

.center {
  margin: auto;
}

.p-state-default {
  border: 1px solid #b4bec5;
}

.p-corner-all {
  border-radius: 0;
}

// ---------- Styles for the tree views ----------

// Note: styles for the angular tree component need to reside in styles.scss,
// so Angular's view encapsulation does not kick in
.tree {
  height: 100%;
  overflow: hidden;

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1px;
  }

  tree-node-content {
    vertical-align: bottom;
    cursor: pointer;
  }

  .white {
    fill: colors.$white;
  }
}

// ---------- Styles for the endpoints tree view ----------
.endpoints-tree {
  @extend .tree;

  color: colors.$secondary-dark;

  .tree-node {
    line-height: 32px;
    padding-left: 24px;

    &.routeable {
      font-weight: normal;
      cursor: pointer;
      pointer-events: all;
    }

    &:not(.routeable) {
      font-weight: bold;
      cursor: default;
      pointer-events: none;
    }
  }

  span.active {
    background-color: colors.$primary-light;
    padding-right: 3px;
    color: colors.$white;
    border-radius: 0.2em;
  }
}

.p-autocomplete {
  width: 100%;
}

as-split {
  .as-split-gutter-icon:hover {
    background-color: colors.$lightgray-2;
  }

  .as-split-area {
    overflow: hidden;
    position: relative;
  }
}

.highlight {
  font-weight: bold;
}

app-sick-footer {
  margin-top: auto;
}

.inputFilter {
  margin-bottom: 0.25em;
  height: 1.7em;
  margin-top: 0.25em;
  text-indent: 0;
  padding: 0 0.5em;
}

.no-padding {
  padding: 0;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tooltipIcon {
    min-height: 1em;
    min-width: 1em;
    max-height: 1em;
    max-width: 1em;
  }
}

.actions-panel {
  > * {
    &:not(:last-child) {
      margin-right: 1em;
    }
    cursor: pointer;
  }
  display: flex;
  justify-content: flex-end;
}

.bare-neutral {
  fill: colors.$darkgray-2;
  cursor: pointer;
  &:hover {
    fill: colors.$darkgray-4;
  }
}

.simple-callout {
  padding: 1em;
  margin: 0.5em 0 0.5em 0;
  border: 1px solid colors.$midgray-3;
  word-break: break-word;

  &.success {
    background-color: colors.$success-light;
    border: none;
  }

  &.error {
    background-color: colors.$error-light;
    border: none;
  }

  &.warn {
    background-color: colors.$warning-light;
    border: none;
  }
}

.dialog-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  button {
    min-width: 140px;
    &:not(:first-child) {
      margin-left: 15px;
    }
    margin-bottom: 0;
  }
}

.plaintext {
  padding: 1em;
  border: 1px solid colors.$midgray-1;
  background-color: colors.$lightgray-3;
  width: 100%;
  font-family: monospace;
  line-height: normal;

  font-size: medium;
  white-space: pre-wrap;
}

// ---------- Styles for the header and gallery image ----------
.image-frame {
  width: inherit;
  border: 1px solid colors.$midgray-3;
  width: 300px;
  height: 300px;
  img {
    width: inherit;
  }

  .main-image-frame {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
  }
  .main-image-frame:hover .overlay {
    visibility: visible;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 35px;
    color: colors.$darkgray-2;
    background-color: rgba(colors.$white, 0.5);
    visibility: hidden;
  }
}

.image-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
// ---------- End of the styles for the header and gallery image ----------

// ---------- Styles for the channel tables ----------
$row-margin: 1.5em;
$row-height: 24px;

.channel-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  margin-top: 1em;

  table {
    table-layout: fixed;
    border: 1px solid colors.$midgray-3;
    border-radius: 4px 4px 0px 0px;
    border-collapse: separate;
    overflow: hidden;

    th {
      height: calc(46rem / 14);
      padding-left: 1em;
      border-bottom: 1px solid colors.$midgray-3;
      background-color: colors.$lightgray-2;
    }

    .scroll {
      width: 100%;
      height: calc(8 * ($row-height + $row-margin) + $row-margin);
      @media (max-height: 760px) {
        height: calc(4 * ($row-height + $row-margin) + $row-margin);
      }
      overflow-y: auto;
    }

    .row {
      margin: $row-margin 1em $row-margin 1em;

      label {
        line-height: $row-height;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .profile {
      p-checkbox {
        margin: 0 0.5rem 0 0;
      }
    }

    tr:last-child {
      height: 99%;
    }
  }

  .destination {
    width: 100%;
    th:last-child {
      text-align: right;
    }
  }

  .profiles {
    width: 100%;
  }
}
// ---------- End of the styles for the channel tables ----------

.align-right {
  display: flex;
  justify-content: flex-end;
}

.align-center-vertical {
  display: flex;
  align-items: center;
}

app-icon.action-button {
  max-height: 24px;
  max-width: 24px;
  fill: colors.$darkgray-2;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
  }

  &:hover,
  &.disabled {
    opacity: 0.5;
  }

  &.primary {
    fill: colors.$primary-light;
  }
}

.pointer {
  cursor: pointer;
}

.formatted-text {
  color: colors.$text;
  img {
    max-width: 100%;
    vertical-align: bottom;
  }
  strong {
    font-weight: $font-bold;
  }
  em {
    font-style: italic;
  }
  blockquote {
    display: block;
    margin-left: 1rem;
  }
  code {
    font-family: monospace;
  }
  table {
    margin: 0 1rem;
    thead {
      font-weight: $font-bold;
      color: colors.$text-light;
      background-color: colors.$background-3;
      & > tr {
        & > th {
          padding: 12px;
        }
      }
    }
    tbody {
      & > tr {
        &:nth-child(even) {
          background-color: colors.$background-3;
        }
        &:nth-child(odd) {
          background-color: colors.$white;
        }
        &:nth-child(odd):last-child {
          border-bottom: 1px solid colors.$background-3;
        }
        &.p-highlight {
          background-color: colors.$accent;
        }
        & > td {
          padding: 12px;
          vertical-align: top;
        }
      }
    }
  }
  ol {
    list-style: decimal;
    margin: 0;
    padding-left: 40px;
  }
  ul {
    list-style: disc;
    margin: 0;
    padding-left: 40px;
    & > ul {
      list-style: circle;
      & > ul {
        list-style: square;
      }
    }
  }
}

.view-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.labeled-element {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 13px 0px;
  > * {
    margin: 0;
  }

  label {
    line-height: 24px;
    cursor: pointer;
    color: var(--syn-color-neutral-950);
    &[disabled],
    &.disabled {
      cursor: default;
      color: colors.$text-disabled;
    }
  }
}
