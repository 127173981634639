@use 'primeng/primeng-theme' as *;
@use 'checkbox' as *;
@use 'synergy/colors' as colors;

p-listbox {
  .p-listbox {
    border: 1px solid colors.$outline;
    border-radius: 0;
    .p-listbox-option,
    .p-listbox-header {
      height: 36px;
      padding: 6px 16px;
      @include checkbox-box;
    }

    /**
     * Workaround for p-listbox in primeng 17: these contain a11y elements since late 16.x, ~17. AssetHub sets
     * a `position: relative;` up in the stack with the `.content` class which lets some aria-role spans that come
     * with listbox position itself way down in the HTML page. It can be fixed by either setting `position: static` to
     * that `.content` class (but it is unclear what could that change break), or just not show these <span>s for the
     * moment.
     *
     * Observe the behavior at http://localhost:4200/gui-check/inputs in Chrome(!) where multiple scrollbars
     * appear without this fix.
     */
    span[role] {
      display: none;
    }

    .p-listbox-list-container .p-listbox-list {
      padding: 0;
      .p-listbox-option {
        p-checkbox {
          padding: 0 8px 0 0;
          pointer-events: none;
        }
        &.p-listbox-option-selected {
          color: colors.$text;
          background-color: colors.$lightgray-3;
          border-left: 6px solid colors.$primary-light;
          padding-left: 10px;
          border-radius: 0;
        }
        &:focus:not(:active) {
          box-shadow: none;
          outline: 1px solid colors.$primary;
          outline-offset: -1px;
        }
        &:focus:not(:focus-visible) {
          outline: none;
        }
        // 'not' makes this rule as specific as focus rule above.
        // Otherwise hovering over the most recently clicked row
        // does not change the border of the checkbox.
        &:hover:not(.p-disabled) {
          background-color: colors.$lightgray-3;
        }
        &.p-disabled {
          color: colors.$text-disabled;
          &.p-listbox-option-selected {
            background-color: colors.$background;
            border-left: 6px solid colors.$outline-light;
          }
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .p-listbox-header {
      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid colors.$outline;

      .p-checkbox p-checkbox {
        margin: 0;
      }
    }
  }
}
